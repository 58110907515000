import {useRef, useCallback, useEffect} from 'react';
import Editor from '../libs/editor/editor';
import UploadModal from './UploadModal';
import ExportModal from './ExportModal';
import HelpModal from './HelpModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const C = () => {
  const editorRef = useRef(null);
  const editorDOMRef = useRef(null);
  const uploadModalRef = useRef(null);
  const helpModalRef = useRef(null);
  const exportModalRef = useRef(null);

  useEffect(() => {
    /*
    if (editorRef.current) {
      editorRef.current.destroy();
    }
    */
    if (!editorRef.current) {
      editorRef.current = new Editor(editorDOMRef.current);
    }
    //editorRef.current.init();
  }, []);

  const onAssetAdd = useCallback((file, model) => {
    window.gtag('event', 'add-asset', {name: file.name, size: file.size});
    editorRef.current.addAsset(file, model);
  }, []);

  const onExport = useCallback(() => {
    window.gtag('event', 'export-project', {itemCount: editorRef.current.contents.items.length});
    editorRef.current.export();
  }, []);

  return (
    <div className="editor-page">
      <UploadModal />
      <div className="menu">
        <div className="left">
          <img src="/assets/images/logo.png"/>
          MindAR Studio (beta)
        </div>
        <div className="right">
          <button className="button primary" onClick={() => exportModalRef.current.open()}>Export</button>
        </div>
      </div>

      <UploadModal ref={uploadModalRef} onAssetAdd={onAssetAdd} />
      <HelpModal ref={helpModalRef} />
      <ExportModal ref={exportModalRef} onExport={onExport}/>

      <div className="editor-wrapper" ref={editorDOMRef}>
        <div className="viewport">
          <canvas/>
        </div>

        <div className="assets-panel">
          <div className="list">
          </div>
          <div className="add-asset">
            <button className="button primary" onClick={() => uploadModalRef.current.open()}>+ 3D Asset</button>
          </div>
        </div>

        <div className="transform-panel">
          <div className="buttons">
            <button className="translate-button button">
              <FontAwesomeIcon icon='arrows-alt' />
            </button>
            <button className="rotate-button button">
              <FontAwesomeIcon icon='sync-alt' />
            </button>
            <button className="scale-button button">
              <FontAwesomeIcon icon='expand-alt' />
            </button>
          </div>
        </div>

        <div className="properties-panel">
          <div className="section">
            <div className="sub-title">Position</div>
            <div className="row">
              <input type="number" className="col position-x" />
              <input type="number" className="col position-y" />
              <input type="number" className="col position-z" />
            </div>
            <div className="sub-title">Rotation</div>
            <div className="row">
              <input type="number" className="col rotation-x" />
              <input type="number" className="col rotation-y" />
              <input type="number" className="col rotation-z" />
            </div>
            <div className="sub-title">Scale</div>
            <div className="row">
              <input type="number" className="col scale-x" />
              <input type="number" className="col scale-y" />
              <input type="number" className="col scale-z" />
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="left">
          <div className="help" onClick={() => helpModalRef.current.open()}>
            <FontAwesomeIcon icon='lightbulb' />
            Help
          </div>
        </div>
        <div className="right">
          <a target="_blank" href="https://mindar.org">&copy; mindar.org</a>
        </div>
      </div>
    </div>
  )
}

export default C;
