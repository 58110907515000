// ref: https://github.com/sbtron/makeglb/blob/master/index.html
const checkIsGLTF = (files) => {
  for (const file of files) {
    const extension = file.name.split( '.' ).pop().toLowerCase();
    if (extension === 'gltf') {
      return true;
    }
  }
  return false;
}

const convertToGLB = (files) => {
  return new Promise(async (resolve, reject) => {
    let modelName = '';
    let gltf = null;
    let fileblobs = {};

    const promises = [];
    for (const file of files) {
      const filePromise = new Promise((fileResolve) => {
        const extension = file.name.split( '.' ).pop().toLowerCase();
        const reader = new FileReader();
        if (extension === 'gltf') {
          const modelName = file.name.split(".")[0];
          reader.addEventListener( 'load', async (event) => {
            fileResolve({type: 'gltf', modelName, buf: event.target.result});
          });
          reader.readAsText(file);
        } else {
          reader.addEventListener( 'load', async (event) => {
            fileResolve({type: 'file', filename: file.name.toLowerCase(), buf: event.target.result});
          });
          reader.readAsArrayBuffer(file);
        }
      });
      promises.push(filePromise);
    }
    const loadResults = await Promise.all(promises);
    for (const res of loadResults) {
      if (res.type === 'gltf') {
        gltf = JSON.parse(res.buf);
        modelName = res.modelName;
      } else {
        fileblobs[res.filename] = res.buf;
      }
    }

    const glbBlob = await processBuffers(gltf, fileblobs);
    const glbFile = new File([glbBlob], modelName + ".glb");
    resolve(glbFile);
  })
}

const processBuffers = async (gltf, fileblobs) => {
  const bufferMap = new Map();
  const outputBuffers = [];
  let bufferOffset = 0;

  const pendingBuffers = gltf.buffers.map((buffer, bufferIndex) => {
    return dataFromUri(buffer, fileblobs)
      .then((data) => {
        if (data !== undefined) {
          outputBuffers.push(data);
        }
        delete buffer.uri;
        buffer.byteLength = data.byteLength;
        bufferMap.set(bufferIndex, bufferOffset);
        bufferOffset += alignedLength(data.byteLength);
      });
  });

  await Promise.all(pendingBuffers)
    .then(function() {
        var bufferIndex = gltf.buffers.length;
        var images = gltf.images || [];
        var pendingImages = images.map(function (image) {
          return dataFromUri(image, fileblobs).then(function(data) {
            if (data === undefined) {
                delete image['uri'];
                return;
            }
            var bufferView = {
                buffer: 0,
                byteOffset: bufferOffset,
                byteLength: data.byteLength,
            };
            bufferMap.set(bufferIndex, bufferOffset);
            bufferIndex++;
            bufferOffset += alignedLength(data.byteLength);
            var bufferViewIndex = gltf.bufferViews.length;
            gltf.bufferViews.push(bufferView);
            outputBuffers.push(data);
            image['bufferView'] = bufferViewIndex;
            image['mimeType'] = getMimeType(image.uri);
            delete image['uri'];
          });
        });
        return Promise.all(pendingImages);
    });

    var Binary = {
        Magic: 0x46546C67
    };

    for (var _i = 0, _a = gltf.bufferViews; _i < _a.length; _i++) {
        var bufferView = _a[_i];
        if(bufferView.byteOffset=== undefined){
            bufferView.byteOffset=0;
        }
        else{
        bufferView.byteOffset = bufferView.byteOffset + bufferMap.get(bufferView.buffer);
      }
        bufferView.buffer = 0;
    }
    var binBufferSize = bufferOffset;
    gltf.buffers = [{
        byteLength: binBufferSize
    }];

    var enc = new TextEncoder();
    var jsonBuffer = enc.encode(JSON.stringify(gltf));
    var jsonAlignedLength = alignedLength(jsonBuffer.length);
    var padding;
    if (jsonAlignedLength !== jsonBuffer.length) {

        padding = jsonAlignedLength- jsonBuffer.length;
    }
    var totalSize = 12 + // file header: magic + version + length
        8 + // json chunk header: json length + type
        jsonAlignedLength +
        8 + // bin chunk header: chunk length + type
        binBufferSize;
    var finalBuffer = new ArrayBuffer(totalSize);
    var dataView = new DataView(finalBuffer);
    var bufIndex = 0;
    dataView.setUint32(bufIndex, Binary.Magic, true);
    bufIndex += 4;
    dataView.setUint32(bufIndex, 2, true);
    bufIndex += 4;
    dataView.setUint32(bufIndex, totalSize, true);
    bufIndex += 4;
    // JSON
    dataView.setUint32(bufIndex, jsonAlignedLength, true);
    bufIndex += 4;
    dataView.setUint32(bufIndex, 0x4E4F534A, true);
    bufIndex += 4;

    for (let j=0;j<jsonBuffer.length;j++){
        dataView.setUint8(bufIndex, jsonBuffer[j]);
        bufIndex++;
    }
    if(padding!==undefined){
        for (let j=0;j<padding;j++){
            dataView.setUint8(bufIndex, 0x20);
        bufIndex++;
    }
    }

    // BIN
    dataView.setUint32(bufIndex, binBufferSize, true);
    bufIndex += 4;
    dataView.setUint32(bufIndex, 0x004E4942, true);
    bufIndex += 4;
    for (var i = 0; i < outputBuffers.length; i++) {
      var bufoffset = bufIndex + bufferMap.get(i);
      var buf = new Uint8Array(outputBuffers[i]);
      var thisbufindex=bufoffset;
      for (var j=0;j<buf.byteLength;j++){
        dataView.setUint8(thisbufindex, buf[j]);
        thisbufindex++;
      }
    }

  return finalBuffer;
}

const dataFromUri = (buffer, fileblobs) => {
  if (buffer.uri === undefined) {
    return Promise.resolve(undefined);
  } else if (isBase64(buffer.uri)) {
    return decodeBase64(buffer.uri);
  } else {
    const filename=buffer.uri.substr(buffer.uri.lastIndexOf('/')+1);
    return Promise.resolve(fileblobs[filename.toLowerCase()]);
  }
}

const alignedLength = (value) => {
  var alignValue = 4;
  if (value === 0) {
    return value;
  }
  var multiple = value % alignValue;
  if (multiple === 0) {
    return value;
  }
  return value + (alignValue - multiple);
}

const isBase64 = (uri) => {
  return uri.length < 5 ? false : uri.substr(0, 5) === "data:";
}
const decodeBase64 = (uri) => {
  return fetch(uri).then((response) => { 
    return response.arrayBuffer();
  });
}
const getMimeType = (filename) => {
  for (const mimeType in gltfMimeTypes) {
    for (const extensionIndex in gltfMimeTypes[mimeType]) {
      const extension = gltfMimeTypes[mimeType][extensionIndex];
      if (filename.toLowerCase().endsWith('.' + extension)) {
	return mimeType;
      }
    }
  }
  return 'application/octet-stream';
}
const gltfMimeTypes = {
    'image/png': ['png'],
    'image/jpeg': ['jpg', 'jpeg'],
    'text/plain': ['glsl', 'vert', 'vs', 'frag', 'fs', 'txt'],
    'image/vnd-ms.dds': ['dds']
};

export {
  checkIsGLTF,
  convertToGLB
}
