import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';

class TransformPanel {
	constructor(editor, viewport) {
		this.editor = editor;
    this.viewport = viewport;
		this.setup();
	}

	setup() {
    const {viewport, editor} = this;
    const {camera, renderer} = viewport;

    const panel = this.editor.container.querySelector(".transform-panel");
    const translateEl = panel.querySelector(".translate-button");
    const rotateEl = panel.querySelector(".rotate-button");
    const scaleEl = panel.querySelector(".scale-button");

    this.panel = panel;

    const transformControls = new TransformControls(camera, renderer.domElement);

    const changeListener = () => {
      if (!editor.selectedItem) return;
      const targetModel = editor.selectedItem.model;
      editor.signals.selectedItemPropertiesChanged.dispatch({from: "viewport"});
    }
    const mouseDownListener = () => {
      viewport.editorControls.enabled = false;
    }
    const mouseUpListener = () => {
      viewport.editorControls.enabled = true;
    }

    transformControls.addEventListener('change', changeListener);
    transformControls.addEventListener('mouseDown', mouseDownListener);
    transformControls.addEventListener('mouseUp', mouseUpListener);

    const elements = {
      rotate: rotateEl,
      translate: translateEl,
      scale: scaleEl,
    };

    const changeMode = (mode) => {
      const targetModel = editor.selectedItem.model;

      Object.keys(elements).forEach((key) => {
        if (key === mode) {
          if (elements[key].classList.contains("selected")) {
            elements[key].classList.remove("selected");
            transformControls.detach();
          } else {
            elements[key].classList.add("selected");
            transformControls.attach(targetModel);
            transformControls.setMode(key);
          }
        } else {
          elements[key].classList.remove("selected");
        }
      });
    }
    Object.keys(elements).forEach((key) => {
      elements[key].addEventListener("click", () => {
        changeMode(key);
      });
    });

    editor.signals.selectedItemChanged.add(() => {
      if (editor.selectedItem) {
        this.panel.classList.add("show");
        transformControls.attach(editor.selectedItem.model);
        changeMode('translate');
      } else {
        this.panel.classList.remove("show");
        transformControls.detach();
      }
      editor.signals.reRender.dispatch();
    });

    const stopPropagateHandler  = (e) => {
      e.stopPropagation();
    }
    panel.addEventListener("mousedown", stopPropagateHandler);
    panel.addEventListener("mouseup", stopPropagateHandler);

    viewport.sceneHelper.add(transformControls);
  }
}

export default TransformPanel;
