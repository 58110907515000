class AssetsPanel {
	constructor(editor) {
		this.editor = editor;
    this.setup();
		this._itemEls = {};
	}

	setup() {
    const {editor} = this;
    const panel = editor.container.querySelector(".assets-panel");
		this.panel = panel;
		this.list = panel.querySelector(".list");

    editor.signals.assetsChanged.add(() => {
			//console.log("assets", editor.contents);
			this._refreshList();
		});

    editor.signals.selectedItemChanged.add(() => {
			Object.keys(this._itemEls).forEach((itemId) => {
				if (editor.selectedItem && editor.selectedItem.id === itemId) {
					this._itemEls[itemId].classList.add("selected");
				} else {
					this._itemEls[itemId].classList.remove("selected");
				}
			});
		});
	}
	
	_refreshList() {
		const {editor} = this;

		while (this.list.lastElementChild) {
			this.list.removeChild(this.list.lastElementChild);
		}

		let assetIndex = 1; 
		editor.contents.items.forEach((item) => {
			if (item.options && item.options.isHead) return;

			const itemEl = this._buildItemEl(item);
			itemEl.querySelector(".name").innerHTML = "asset " + assetIndex++;
			if (editor.selectedItem === item) {
				itemEl.classList.add("selected");
			} else {
				itemEl.classList.remove("selected");
			}
			this.list.appendChild(itemEl);
		});
	}

	_buildItemEl(item) {
		const {editor} = this;

		if (!this._itemEls[item.id]) {
			const itemEl = document.createElement("div");
			itemEl.classList.add("item");
			const nameEl = document.createElement("div");
			nameEl.classList.add("name");
			itemEl.appendChild(nameEl);

			itemEl.addEventListener("click", () => {
				editor.selectItem(item);
			});
			this._itemEls[item.id] = itemEl;
		}
		return this._itemEls[item.id];
	}
}
export default AssetsPanel;
