import * as THREE from 'three';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';
import {EditorControls} from './EditorControls.js';
import {loadGLTF} from '../../libs/loaders.js';
import {createThreeFaceGeometry} from './face/face-geometry';

// Face geoemetry at centered at 0,0,0 (same as the scene)

class Viewport {
	constructor(editor) {
    this.editor = editor;
    this.setup();
	}

  addToScene(obj) {
    this.assetRoot.add(obj);
    this.render();
  }

  removeFromAnchor(obj) {
    this.assetRoot.remove(obj);
  }

  setup() {
    const {editor} = this;
    this.container = this.editor.container.querySelector(".viewport");

    const renderer = new THREE.WebGLRenderer({antialias: true, alpha: true, canvas: this.container.querySelector("canvas") });
    renderer.autoClear = false;
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.setClearColor( 0xebeced );
    renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer = renderer;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(50, 1, 1, 100000);
    camera.position.set(-15, 5, 35);
    camera.rotation.set(-0.16, -0.37, -0.059);
    camera.lookAt(new THREE.Vector3());

    const pmremGenerator = new THREE.PMREMGenerator(renderer);
    pmremGenerator.compileEquirectangularShader();
    scene.environment = pmremGenerator.fromScene(new RoomEnvironment(), 0.04).texture;

    const assetRoot = new THREE.Group();
    this.assetRoot = assetRoot;
    scene.add(assetRoot);

    const faceGeometry = createThreeFaceGeometry();
    const mesh = new THREE.Mesh(faceGeometry, new THREE.MeshStandardMaterial({color: new THREE.Color(0.5, 0.5, 0.5), side: THREE.DoubleSide}));
    scene.add(mesh);

    /*
    const anchorIndex = 168;
    const faceScale = 15;
    const anchor = new THREE.Group();
    anchor.position.set(faceGeometry.positions[anchorIndex*3], faceGeometry.positions[anchorIndex*3+1], faceGeometry.positions[anchorIndex*3+2]);
    scene.add(anchor);
    */

    /*
    const content = glasses.scene;
    this.contents.assets.push({});
    this.contents.items.push({
      assetId: 0,
      model: content
      //position: content.position.toArray(),
      //rotation: content.rotation.toArray(),
      //scale: content.scale.toArray(),
    });
    */

    const sceneHelper = new THREE.Scene();

    const grid1 = new THREE.GridHelper( 300, 30, 0x000000 );
    //grid1.material.color.setHex( 0x1976d2 );
    grid1.material.color.setHex( 0xAAAAAA );
    grid1.material.transparent = true;
    grid1.material.opacity = 0.1;
    grid1.material.vertexColors = false;
    const grid2 = new THREE.GridHelper( 300, 30, 0x222222 );
    //grid2.material.color.setHex( 0x004ba0 );
    grid2.material.color.setHex( 0xAAAAAA );
    grid2.material.transparent = true;
    grid2.material.opacity = 0.5;
    grid2.material.needsUpdate = true;
    const grid = new THREE.Group();
    grid.add(grid1);
    grid.add(grid2);
    sceneHelper.add(grid);

    const selectionBox = new THREE.BoxHelper();
    selectionBox.material.color.set(0x222222);
    selectionBox.material.depthTest = false;
    selectionBox.material.transparent = true;
    selectionBox.visible = false;
    sceneHelper.add(selectionBox);

    this.selectionBox = selectionBox;
    this.renderer = renderer;
    this.camera = camera;
    this.scene = scene;
    this.sceneHelper = sceneHelper;
    //this.anchor = anchor;

    this.editorControls = new EditorControls(camera, this.container);
    this.editorControls.addEventListener('change', () => this.render());

    this._setupSelectionEvents();

    this.resizeHandler();
    window.addEventListener("resize", this.resizeHandler.bind(this)); 

    this.editor.signals.selectedItemChanged.add(() => {
      if (this.editor.selectedItem) {
        selectionBox.setFromObject(this.editor.selectedItem.model);
        selectionBox.visible = true;
      } else {
        selectionBox.visible = false;
      }
    });

    this.editor.signals.selectedItemPropertiesChanged.add(() => {
      selectionBox.setFromObject(this.editor.selectedItem.model);
      this.render();
    });

    this.editor.signals.reRender.add(() => {
      this.render();
    });
  }

  _setupSelectionEvents() {
    const {editorControls, container, editor, camera} = this;

    let downPos;
    const mouseDownHandler = (e) => {
      downPos = [e.clientX, e.clientY];
    };
    const mouseUpHandler = (e) => {
      if (e.clientX !== downPos[0] || e.clientY !== downPos[1]) return;
      const item = this._getIntersectObject(camera, container, e.clientX, e.clientY);
      editor.selectItem(item);
    };
    const doubleClickHandler = (e) => {
      const item = this._getIntersectObject(camera, container, e.clientX, e.clientY);
      if (!item) return;
      editorControls.focus(item.model);
      this.render();
    };

    this.container.addEventListener('mouseup', mouseUpHandler);
    this.container.addEventListener('mousedown', mouseDownHandler);
    this.container.addEventListener('dblclick', doubleClickHandler);
  }

  _getIntersectObject(camera, container, x, y) {
    const {scene, editor} = this;

    const rect = container.getBoundingClientRect();
    const point = [ ( x - rect.left ) / rect.width, ( y - rect.top ) / rect.height ];

    const mouse = new THREE.Vector2(point[0]*2-1, -(point[1]*2) + 1);
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, camera);
    
    const intersects = raycaster.intersectObjects(scene.children, true);
    if (intersects.length === 0) return null;

    let o = intersects[0].object; 
    while (o.parent && o.parent !== this.assetRoot) {
      o = o.parent;
    }
    return editor.contents.items.find((item) => (!item.options || !item.options.isHead) && item.model === o);
  }

  render() {
    const {scene, sceneHelper, camera, renderer} = this;
    renderer.render(scene, camera);
    renderer.render(sceneHelper, camera);
  }

  resizeHandler() {
    const {renderer, camera, container} = this;
    renderer.setViewport(0, 0, container.offsetWidth, container.offsetHeight);
    renderer.setSize(container.offsetWidth, container.offsetHeight, false);
    camera.aspect = container.offsetWidth / container.offsetHeight;
    camera.updateProjectionMatrix();
    this.render();
  }
}

export default Viewport;
