const KEY = "AZFsTHijixBTxWMfr_Q7I3bH1xyAv_BbHH4io0LRv2X";
const ALGORITHM_IV = "216,225,153,67,121,123,48,86,238,29,177,186";

export const encryptBlob = async (blob) => {
  const keyData = {
    "alg": "A256GCM",
    "ext": true,
    "k": KEY,
    "key_ops": [
      "encrypt",
      "decrypt"
    ],
    "kty": "oct"
  };

  const projectBlobData = await blob.arrayBuffer();
  //const iv = crypto.getRandomValues(new Uint8Array(12));
  //const key = await crypto.subtle.generateKey({name: "AES-GCM", length: 256}, true, ["encrypt", "decrypt"]);
  //const algorithm = {name: "AES-GCM", iv: iv}
  //const exportedKey =  await crypto.subtle.exportKey("jwk", key);

  const algorithm = {name: "AES-GCM", iv: new Uint8Array(ALGORITHM_IV.split(","))}
  const key = await crypto.subtle.importKey("jwk", keyData, {name: "AES-GCM"}, true, ["encrypt", "decrypt"]);

  const cryptoResult = await crypto.subtle.encrypt(algorithm, key, projectBlobData);
  const encryptedBlob = new Blob([cryptoResult]);
  return encryptedBlob;
}
