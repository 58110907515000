import * as THREE from 'three';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

let gltfLoader = null;

const _getGLTFLoader = async () => {
  if (gltfLoader) return gltfLoader;

  const dracoLoader = new DRACOLoader();
  //dracoLoader.setDecoderPath( '/vendor/three/js/libs/draco/gltf/' );
  dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.4.1/');

  const loader = new GLTFLoader();
  loader.setDRACOLoader(dracoLoader);

  gltfLoader = loader;
  return gltfLoader;
}

export const loadGLBModel = (file) => {
  return new Promise(async (resolve, reject) => { 
    const reader = new FileReader();
    reader.addEventListener( 'load', async (event) => {
      const contents = event.target.result;
      const loader = await _getGLTFLoader();
      loader.parse(contents, '', (result) => {
        const scene = result.scene;
        scene.animations.push(...result.animations);
        resolve(scene);
      });
    });
    reader.readAsArrayBuffer(file);
  });
}

export const loadGLTF = (path) => {
  return new Promise(async (resolve, reject) => {
    const loader = new GLTFLoader();
    loader.load(path, (gltf) => {
      resolve(gltf);
    });
  });
}

export const loadAudio = (path) => {
  return new Promise((resolve, reject) => {
    const loader = new THREE.AudioLoader();
    loader.load(path, (buffer) => {
      resolve(buffer);
    });
  });
}

export const loadVideo = (path) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.addEventListener('loadedmetadata', () => {
      video.setAttribute('playsinline', '');
      resolve(video);
    });
    video.crossOrigin = "anonymous";
    video.src = path;
  });
}

export const loadTexture = (path) => {
  return new Promise((resolve, reject) => {
    const loader = new THREE.TextureLoader();
    loader.load(path, (texture) => {
      resolve(texture);
    }); 
  });
}

export const loadTextures = (paths) => {
  const loader = new THREE.TextureLoader();
  const promises = [];
  for (let i = 0; i < paths.length; i++) {
    promises.push(new Promise((resolve, reject) => {
      loader.load(paths[i], (texture) => {
	resolve(texture);
      }); 
    }));
  }
  return Promise.all(promises);
}

export const loadImage = (path) => {
  const loader = new THREE.ImageLoader();
  return new Promise((resolve, reject) => {
    loader.load(path, (texture) => {
      resolve(texture);
    });
  });
}

export const imageSrcToBlob = (imgSrc) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      context.drawImage(img, 0, 0);
      canvas.toBlob((blob) => {        // get content as JPEG blob
        resolve(blob);
      }, "image/png");
    }
    img.src = imgSrc;
  });
}
