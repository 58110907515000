import React, {useMemo, forwardRef, useCallback, useState} from 'react';
import Modal from './Modal';

const ExportModal = (props, ref) => {
  return (
    <Modal {...props} ref={ref} className="help-modal" header={"Export Project"}>
      <div>
        <p>The exported project is a static website. Simply download, unzip and paste the whole folder in your web server.</p>
        <button className="button primary" onClick={props.onExport}>Download</button>
      </div>
    </Modal>
  )
}

export default forwardRef(ExportModal);
