import React, {useMemo, forwardRef, useCallback, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from './Modal';
import {useDropzone} from 'react-dropzone'
import {checkIsGLTF, convertToGLB} from '../libs/gltfConverter'; 
import {loadGLBModel} from '../libs/loaders'; 

const UploadModal = (props, ref) => {
  const {onAssetAdd} = props;
  const {processingStatus, setProcessingStatus} = useState(false);

  const addSampleModel = useCallback(async () => {
    const glassesResponse = await fetch('/assets/sample-models/mindar-glasses/glasses.glb');
    const glassesBlob = await glassesResponse.blob();
    const glassesFile = new File([glassesBlob], 'glasses.glb');
    const glasses = await loadGLBModel(glassesFile);
    //glasses.position.set(0, 0.2582290733615009, -1.0833171992752484);
    glasses.position.set(0, -0.581, 5.844);
    glasses.scale.multiplyScalar(9);
    onAssetAdd(glassesFile, glasses);
    ref.current.close();
  }, [onAssetAdd]);

  const onDrop = useCallback(async (files) => {
    let file;

    // if gltf, then convert it to glb first
    const isGLTF = checkIsGLTF(files);
    if (isGLTF) {
      file = await convertToGLB(files);
    } else {
      file = files[0];
    }

    //console.log("file", file);
    const model = await loadGLBModel(file);
    //console.log("model", model);
    onAssetAdd(file, model);
    ref.current.close();
  }, [onAssetAdd]);

  const {getRootProps, getInputProps} = useDropzone({
    multiple: true,
    onDrop
  });

  return (
    <Modal {...props} ref={ref} className="upload-modal" header={"Upload"}>
      <div>
        {processingStatus && (
          <div className="processing">
            <div className="inner">
              <FontAwesomeIcon icon="spinner" spin size="xs" />
              {processingStatus}
            </div>
          </div>
        )}

        <div className="dropzone" {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="title">Drop files here, or click to select</div>
          <div className="format">
            <div><strong>.glb</strong> or <strong>.gltf</strong> (drop the whole folder)</div>
          </div>
        </div>

        <div className="or">
          --- OR ---
        </div>

        <div className="samples" onClick={addSampleModel}>Try a sample model <img src="/assets/images/sample-glasses.png"/></div>
      </div>
    </Modal>
  )
}

export default forwardRef(UploadModal);
