import React, {useMemo, forwardRef, useCallback, useState} from 'react';
import Modal from './Modal';

const HelpModal = (props, ref) => {
  return (
    <Modal {...props} ref={ref} className="help-modal" header={"Instructions"}>
      <div>
        <ol>
          <li>Add 3D assets to the scene</li>
          <li>Adjust their scales, rotations and positions relative to the head</li>
          <li>Export the project and host it anywhere</li>
        </ol>
      </div>
    </Modal>
  )
}

export default forwardRef(HelpModal);
