import Editor from './components/Editor';

function App() {
  return (
    <div className="App">
      <Editor/>
    </div>
  );
}

export default App;
