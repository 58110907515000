import React, {useImperativeHandle, useLayoutEffect, forwardRef, useState, useCallback} from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';

const Modal = forwardRef(({children, header, className, onOpen, onClose, forbidClose}, ref) => {
  const [show, setShow] = useState(false);
  const [params, setParams] = useState(null);

  const handleOpen = useCallback(() => {
    setShow(true);
  }, [setShow]);

  const doClose = useCallback((force) => {
    if (!force && forbidClose) return;
    setShow(false);
    onClose && onClose();
  }, [setShow, onClose, forbidClose]);

  const handleClose = useCallback(() => {
    doClose(false);
  }, [doClose]);

  useImperativeHandle(ref, () => ({
    close: () => {
      doClose(true);
    },
    open: (params) => {
      setParams(params);
      handleOpen();
    }
  }), [doClose, handleOpen, setParams]);

  useLayoutEffect(() => {
    if (show && onOpen) onOpen(params);
  }, [show, onOpen, params]);

  if (!show) return null;

  return (
    <div className={classnames("modal", className, {"show": show})}>
      <div className="modal-mask" onClick={handleClose}></div>
      <div className="modal-inner">
        <div className="modal-header">
          <div className="title">{header}</div>
          <div className="close" onClick={handleClose}>&times;</div>
        </div>
        <div className="modal-content">
          {children}
        </div>
      </div>
    </div>
  )
});

const ModalWrapper = (props, ref) => {
  return ReactDOM.createPortal(<Modal {...props} ref={ref} />, document.getElementById('modal-root'));
}

export default forwardRef(ModalWrapper);
