import * as THREE from 'three';

const axes = ['x', 'y', 'z'];

class PropertiesPanel {
	constructor(editor) {
		this.editor = editor;
    this.setup();
	}

	setup() {
    const {editor} = this;
    const panel = editor.container.querySelector(".properties-panel");
    this.positionInputs = [
      panel.querySelector(".position-x"),
      panel.querySelector(".position-y"),
      panel.querySelector(".position-z"),
    ];
    this.rotationInputs = [
      panel.querySelector(".rotation-x"),
      panel.querySelector(".rotation-y"),
      panel.querySelector(".rotation-z"),
    ];
    this.scaleInputs = [
      panel.querySelector(".scale-x"),
      panel.querySelector(".scale-y"),
      panel.querySelector(".scale-z"),
    ];

		this.panel = panel;

    [...this.positionInputs, ...this.rotationInputs, ...this.scaleInputs].forEach((input) => {
      input.addEventListener("change", () => {
        const model = this.editor.selectedItem.model;
        for (let i = 0; i < 3; i++) {
          model.position[axes[i]] = parseFloat(this.positionInputs[i].value);
          model.rotation[axes[i]] = parseFloat(this.rotationInputs[i].value) * THREE.MathUtils.DEG2RAD;
          model.scale[axes[i]] = parseFloat(this.scaleInputs[i].value);
        }
        editor.signals.selectedItemPropertiesChanged.dispatch({from: "properties"});
      });
    });

    editor.signals.selectedItemChanged.add(() => {
      if (editor.selectedItem) {
        this.panel.classList.add("show");
        this._populateValues();
      } else {
        this.panel.classList.remove("show");
      }
    });

    editor.signals.selectedItemPropertiesChanged.add(({from}) => {
      if (from === 'viewport') {
        this._populateValues();
      }
    });
	}

  _populateValues() {
    const {editor} = this;
    const model = editor.selectedItem.model;
    for (let i = 0; i < 3; i++) {
      this.positionInputs[i].value = model.position[axes[i]].toFixed(3);
      this.rotationInputs[i].value = (model.rotation[axes[i]] * THREE.MathUtils.RAD2DEG).toFixed(3);
      this.scaleInputs[i].value = model.scale[axes[i]].toFixed(3);
    }
  }
}

export default PropertiesPanel;
