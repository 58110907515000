import JSZip from 'jszip';
import * as THREE from 'three';
import Signals from 'signals';
import {loadGLTF, loadGLBModel, imageSrcToBlob} from '../../libs/loaders.js';
import TransformPanel from './transformPanel';
import PropertiesPanel from './propertiesPanel';
import AssetsPanel from './assetsPanel';
import Viewport from './viewport';
import {encryptBlob} from '../crypt';

class Editor {
  constructor(container) {
    this.container = container;
    this.selectedItem = null;

    this.signals = {
      selectedItemChanged: new Signals(),
      selectedItemPropertiesChanged: new Signals(),
      assetsChanged: new Signals(),
      reRender: new Signals(),
    }

    this.viewport = new Viewport(this);
    this.transformPanel = new TransformPanel(this, this.viewport);
    this.propertiesPanel = new PropertiesPanel(this);
    this.assetsPanel = new AssetsPanel(this);

    this.setup();

    this.contents = {
      assets: [],
      items: []
    };

    this.addHead();
    //this.addDummyAssets();
  }

  setup() {
    window.addEventListener("keydown", (event) => {
      if (event.code === 'Delete') {
        if (!this.selectedItem) return;
        this.viewport.removeFromAnchor(this.selectedItem.model);
        this.contents.items = this.contents.items.filter((item) => item !== this.selectedItem);
        this.selectItem(null);
        this.signals.assetsChanged.dispatch();
      }
    });
  }

  async export() {
    const baseZip = '/assets/base-build.zip';
    const response = await fetch(baseZip);
    const blob = await response.blob();
    const zip = new JSZip();
    await zip.loadAsync(blob);

    const projectZip = new JSZip();
    const data = {
      items: []
    }

    const assetsFolder = projectZip.folder("assets");
    this.contents.assets.forEach((asset) => {
      assetsFolder.file(asset.uuid, asset.file);
    });

    data.items = this.contents.items.map((item) => {
      return {
        assetId: item.assetId,
        position: item.model.position.toArray(),
        rotation: item.model.rotation.toArray(),
        scale: item.model.scale.toArray(),
        options: item.options
      }
    });
    projectZip.file("data.json", JSON.stringify(data)); 

    const projectBlob = await projectZip.generateAsync({type: 'blob'});

    const encryptedBlob = await encryptBlob(projectBlob);

    //zip.file("build.zip", projectBlob);
    zip.file("_x1", encryptedBlob);
    const buildBlob = await zip.generateAsync({type: 'blob'});

    const aLink = window.document.createElement('a');
    aLink.download = 'mindar-build.zip';
    aLink.href = window.URL.createObjectURL(buildBlob);
    aLink.click();
    window.URL.revokeObjectURL(aLink.href);
  }

  async addDummyAssets() {
    const glassesResponse = await fetch('/assets/sample-models/glasses/scene.glb');
    const glassesBlob = await glassesResponse.blob();
    const glassesFile = new File([glassesBlob], 'glasses.glb');
    const glasses = await loadGLBModel(glassesFile);
    //glasses.position.set(0, 0.2582290733615009, -1.0833171992752484);
    glasses.position.set(0, 0.2582290733615009+3.271027088165283, -1.0833171992752484+5.2360148429870605);
    glasses.scale.multiplyScalar(0.13);
    await this.addAsset(glassesFile, glasses);

    const hatResponse = await fetch('/assets/sample-models/hat/scene.glb');
    const hatBlob = await hatResponse.blob();
    const hatFile = new File([hatBlob], 'hat.glb');
    const hat = await loadGLBModel(hatFile);
    //hat.position.set(1.126373189579577, -1.0029615785220205, -7.609207309420156);
    hat.position.set(1.126373189579577, -1.0029615785220205+3.271027088165283, -7.609207309420156+5.2360148429870605);
    hat.scale.multiplyScalar(0.14);
    await this.addAsset(hatFile, hat);

    const earringResponse = await fetch('/assets/sample-models/earring/scene.glb');
    const earringBlob = await earringResponse.blob();
    const earringFile = new File([earringBlob], 'earring.glb');
    const earring = await loadGLBModel(earringFile);
    //earring.position.set(-8.307450182690673, -6.57507228599793, -9.461871367922912);
    earring.position.set(-8.307450182690673, -6.57507228599793+3.271027088165283, -9.461871367922912+5.2360148429870605);
    earring.scale.multiplyScalar(1);
    await this.addAsset(earringFile, earring);

    const earring2 = await loadGLBModel(earringFile);
    //earring2.position.set(8.307450182690673, -6.57507228599793, -9.461871367922912);
    earring2.position.set(8.307450182690673, -6.57507228599793+3.271027088165283, -9.461871367922912+5.2360148429870605);
    earring2.scale.multiplyScalar(1);
    await this.addAsset(earringFile, earring2);
  }

  async addHead() {
    const path = '/assets/sample-models/head_occluder/scene.glb';
    const response = await fetch(path);
    const blob = await response.blob();
    const file = new File([blob], 'head.glb');
    const model = await loadGLBModel(file);
    model.scale.multiplyScalar(1.1);
    //model.position.set(0, -4.130768546459459, 3.257352232158536);
    model.position.set(0, -0.8597414582941756, 8.493367075145596);

    this.addAsset(file, model, {isHead: true});
  }

  async addAsset(file, model, options) {
    const itemUuid = THREE.MathUtils.generateUUID();
    const uuid =  THREE.MathUtils.generateUUID();
    this.contents.assets.push({uuid, file});
    this.contents.items.push({id: itemUuid, assetId: uuid, model: model, options});
    this.viewport.addToScene(model);
    if (!options || !options.isHead) {
      this.selectItem(this.contents.items[this.contents.items.length-1]);
    }
    this.signals.assetsChanged.dispatch();
  }

  selectItem(item) {
    if (item) {
      this.selectedItem = item;
      this.signals.selectedItemChanged.dispatch();
    } else {
      this.selectedItem = null;
      this.signals.selectedItemChanged.dispatch();
    }
  }
}

export default Editor;
